#home {
    z-index: 0;
}

#home>img {
    width: 100%;
    max-height: 15rem;
    object-fit: cover;
}

#home-hero:before {
    content: '';
    position: absolute;
    z-index: -1;

    background-image: url('https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/writing-kit.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 60rem;
    mask-image: linear-gradient(to top, transparent 10%, black);
}
#home-hero {
    display: flex;
    justify-content: center;
}
#home-hero div>h2 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
}

#home-card-buttons {
    display: flex;
    margin-top: 1.5rem;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 1199px) {

    #home-hero:before {
        background-image: none;
        height: unset;
    }
    
    #home-hero {
        display: block;
    }

    #home-card-buttons {
        display: block;
    }
    #home-card-buttons>* {
        margin: 2rem auto;
        width: 100%;
    }

}