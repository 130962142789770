#about>h1 {
    font-size: 3.5rem;
    text-align: center;
    margin: 3rem auto 4rem auto;
}

#about-profiles {
    display: flex;
    justify-content: space-around;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 1199px) {

    #about>h1 {
        margin: 5rem auto 2rem auto;
    }

    #about-profiles {
        display: block;
    }

}