#display-card {
    background-color: rgb(245, 245, 245);
    opacity: 1;
    color: black;
    margin: 5rem 2rem 0 2rem;
    padding: 1.5rem 2.5rem 3rem 2rem;
    border-radius: 10px;
    height: fit-content;
}
#display-card.small {
    width: 35%;
}
#display-card.medium {
    width: 50%;
}
#display-card.large {
    width: 61%;
}
#display-card-icon {
    display: block;
    margin: 0 auto 0.75rem auto;
    width: 2.5rem;
    max-height: 2.5rem;
}
#display-card h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
}

#display-card ul {
    list-style: disc;
    margin-bottom: 1rem;
}
#display-card li {
    font-size: 1.25rem;
    line-height: 140%;
    margin: auto auto 0.5rem 1.5rem;
}
#display-card li a {
    font-weight: bold;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    #display-card.small, #display-card.medium, #display-card.large {
        width: 60%;
        margin: 3rem auto 0 auto;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #display-card {
        margin: 2rem auto;
        padding: 1.5rem 1.25rem 2rem 1.25rem;
    }

    #display-card.small, #display-card.medium, #display-card.large {
        width: 95%;
    }

}