#about-me-card {
    max-width: 40%;
}
#about-me-card>img {
    width: 18rem;
    height: 18rem;
    object-fit: cover;
    display: flex;
    margin: 0 auto;
    border-radius: 15px;
}
#about-me-card>h1 {
    font-size: 2.2rem;
    margin: 1rem auto 0.5rem auto;
    text-align: center;
}
#about-me-card>h2 {
    font-size: 1.6rem;
    font-weight: 500;
    opacity: 0.8;
    margin: 0 auto 2rem auto;
    text-align: center;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    #about-me-card {
        max-width: 45%;
        margin: 0 1.5rem 0 1.5rem;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #about-me-card {
        max-width: 100%;
        margin: 0 auto 5rem auto;
        padding: 0 3%;
    }
    #about-me-card>img {
        width: 15rem;
        height: 15rem;
    }

}