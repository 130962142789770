#error {
    display: flex;
    justify-content: center;
}

#error-card-buttons {
    display: flex;
    margin-top: 1.5rem;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 1199px) {

    #error-card-buttons {
        display: block;
    }
    #error-card-buttons>* {
        margin: 2rem auto;
        width: 100%;
    }

}