#nav * {
    color: rgb(227, 242, 255);
    font-weight: 600;
    list-style: none;
    margin: auto 0;
}

#desktop-nav {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 1rem;
}

#desktop-nav #nav-title {
    display: flex;
}
#nav-title img {
    margin-right: 1rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
}
#desktop-nav h1 {
    font-size: 2rem;
    font-weight: bold;
}
#desktop-nav h1:hover {
    opacity: 0.7;
}

/* Source: https://css-tricks.com/snippets/css/typewriter-effect/ */
.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.05rem;
    animation: 
        typing 2.5s steps(40, end),
        blink-caret 0.75s step-end 7; /* 7 specifies the number of times this animation plays. Was initially set to 'infinite'. */
}
@keyframes typing { /* The typing effect */
    from { width: 0 }
    to { width: 100% }
}
@keyframes blink-caret { /* The cursor blinking effect */
    from, to { border-color: transparent; }
    50% { border-right: 0.2rem solid orange; }
}

#desktop-nav>ul {
    display: flex;
    margin: auto 0;
}
#desktop-nav>ul>li * {
    font-size: 1.25rem;
    margin: auto 2rem;
}
#desktop-nav>ul>li:hover * {
    color: white;
    border-bottom: 2px solid orange;
}

#mobile-nav {
    display: none;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 1199px) {
    
    #desktop-nav {
        display: none;
    }
    #mobile-nav {
        display: block;
        margin: 0 0 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        z-index: 100;

        position: fixed;
        top: 0;
        left: 0;

        background-color: rgb(13, 36, 56, 0.9);
        backdrop-filter: saturate(400%) blur(10px);
    }
    #mobile-nav-top {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0;
    }
    #hamburger-menu {
        fill: white;
        margin-top: 0.4rem;
        margin-right: 0.7rem;
    }
    #hamburger-menu:hover {
        cursor: pointer;
    }
    #mobile-nav-top h1 {
        font-size: 1.4rem;
        margin-left: 0.7rem;
    }

    #mobile-nav-list {
        font-size: 1.5rem;
    }
    #mobile-nav-list li:last-child {
        padding-bottom: 1rem;
    }
    #mobile-nav-list li, #mobile-nav-list li a {
        font-weight: 600;
        text-align: right;
        padding: 0.4rem 0;
        margin-right: 0.4rem;
    }

}