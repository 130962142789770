.ig-button {
    background-color: #1f84ff;
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5rem 3.5rem;
    border-radius: 5px;

    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
}
.ig-button:hover {
    opacity: 0.8;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 1199px) {

    .ig-button {
        padding: 0.5rem;
    }

}