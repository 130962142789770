#newsletter-promo {
    background-color: rgb(245, 245, 245);
    margin: 4rem auto;
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
    border-radius: 10px;
    width: 50%;
}
#newsletter-promo.normal {
    width: 50%;
}
#newsletter-promo.wide {
    width: 61%;
}



#mail-notification-icon {
    display: block;
    color: black;
    width: 2.5rem;
    margin: 0 auto 0.25rem auto;
}
#newsletter-promo>* {
    text-align: center;
}
#newsletter-promo h1 {
    color: black;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}
#newsletter-promo p {
    color: black;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
}
#newsletter-promo a {
    margin-top: 1.25rem;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    #newsletter-promo.normal {
        width: 60%;
    }
    #newsletter-promo.wide {
        width: 60%;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #newsletter-promo {
        padding: 1.5rem 1rem 2.5rem 1rem;
    }
    #newsletter-promo.normal {
        width: 95%;
    }
    #newsletter-promo.wide {
        width: 95%;
    }

    #newsletter-promo a {
        padding: 0.5rem 1rem;
    }

}