#contact-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
#contact-form label {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
    margin: 0.5rem auto 0 auto;
}
#contact-form input {
    background-color: rgba(211, 211, 211, 0.4);
    color: black;
    font-weight: bold;
    font-size: 1.1rem;
    font-weight: 400;
    width: 100%;
    margin: 0.5rem auto;
    padding: 0 0.5rem;
    height: 2.5rem;
    border-radius: 4px;
}
#contact-form input[type=text] {
    border: 1px solid rgb(211, 211, 211, 0.4);
}
#contact-form input[type=text]:hover {
    background-color: rgba(211, 211, 211, 0.6);
}
#contact-form input[type=button] {
    background-color: #1f84ff;
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    width: fit-content;
    margin: 0.5rem auto;
    padding: 0.2rem 2rem;
    border-radius: 5px;
    border: none;

    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
}
#contact-form input[type=button]:hover {
    cursor: pointer;
    opacity: 0.9;
}

#contact-form-message {
    margin-top: 2rem;
}
#contact-form-message svg {
    display: block;
    margin: 0 auto;
    width: 2.5rem;
    max-height: 2.5rem;
}
#contact-form-message #form-sending svg {
    fill: black;
    color: black;
}
#contact-form-message #form-done svg {
    color: rgb(36, 170, 36);
}
#contact-form-message p {
    font-size: 1.4rem;
    font-weight: 500;
}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #contact-form label {
        width: 100%;
    }
    #contact-form input {
        width: 100%;
        padding: 0;
    }
    #contact-form input[type=button] {
        width: 100%;
        margin: 0.75rem auto;
        padding: 0 1.7rem;
    }

}