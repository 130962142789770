#footer {
    background-color: rgb(13, 36, 56);
    display: flex;
    justify-content: space-between;
    min-height: 10rem;
    border-top: 1px solid rgb(245, 245, 245, 0.2);
    margin-top: 4rem;
}
#footer * {
    font-size: 1.4rem;
    color: rgb(245, 245, 245);
}
#footer>div {
    margin: 2rem 1.5rem;
    max-width: 30%;
}
#footer>div>h1 {
    color: orange;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
#footer>div>p, #footer>div>a, #footer>div>p>a {
    font-size: 1.2rem;
}

#footer-nav a {
    display: flex;
}
#footer-nav a:hover {
    opacity: 0.8;
}

.footer-nav-item {
    margin: 0.5rem auto;
}

#footer a {
    text-decoration: underline;
}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #footer {
        display: block;
        margin-top: 4rem;
    }
    #footer>div {
        margin: 2rem 1rem;
        max-width: 100%;
    }
    #footer>div>p, #footer>div>a, #footer>div>p>a {
        font-size: 1.25rem;
    }

}
