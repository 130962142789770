#samples-bio {
    width: 50%;
    margin: 0 auto;
}
#samples-bio h1 {
    font-size: 3.5rem;
    text-align: center;
    margin: 3rem auto 4rem auto;
}

#samples-columns {
    display: flex;
    width: 90%;
    margin: 4rem auto;
}
.samples-card {
    background-color: rgb(245, 245, 245);
    margin: 0 auto 3rem auto;
    padding: 1.5rem 2rem 3rem 2rem;
    border-radius: 10px;
    height: fit-content;
    width: 45%;
}
.samples-card * {
    color: black;
}
#samples-card-icon {
    display: block;
    margin: 0 auto 0.75rem auto;
    width: 2.5rem;
    max-height: 2.5rem;
}
.samples-card h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 0 auto 1rem auto;
}

.samples-sample-item, .samples-sample-item * {
    font-size: 1.25rem;
    list-style: disc;
    text-decoration: underline;
    margin: 0.7rem auto;
}
.samples-sample-item {
    margin-left: 1.25rem;
}

.samples-product-item {
    display: flex;
    margin: 1.5rem auto;
}
.samples-product-item>div {
    padding: 1rem;
}
.samples-product-item h1 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.samples-product-item p {
    font-size: 1.2rem;
}
.samples-product-item a {
    color: white;
}
.samples-product-item img {
    height: 100%;
    max-width: 10rem;
    object-fit: cover;
    margin: auto 0;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    #samples-bio {
        width: 60%;
    }
    #samples-bio>h1 {
        margin: 5rem auto 2rem auto;
    }

    #samples-columns {
        width: 95%;
    }

    .samples-product-item img {
        display: none;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #samples-bio {
        width: 100%;
        margin: 0 auto;
        padding: 0 3%;
    }
    #samples-bio>h1 {
        margin: 5rem auto 2rem auto;
    }
    #samples-bio>p {
        font-size: 1.25rem;
    }

    #samples-columns {
        display: block;
        width: 100%;
        padding: 0 3%;
    }
    .samples-card {
        padding: 1.5rem 1rem 3rem 1rem;
        width: 100%;
    }
    .samples-sample-item, .samples-sample-item * {
        font-size: 1.25rem;
    }
    .samples-product-item h1 {
        font-size: 2rem;
    }
    .samples-product-item p {
        font-size: 1.25rem;
    }
    .samples-product-item>div>a {
        width: 100%;
    }
    .samples-product-item img {
        display: none;
    }

}