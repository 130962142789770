#work-sample-story {
    width: 50%;
    margin: 3rem auto;
}
#work-sample-story a, #work-sample-story-body ul li p a {
    color: rgb(245, 245, 245);
    font-weight: 400;
    text-decoration: underline;
}

#work-sample-story-body h1 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin: 6rem auto 3rem auto;
}
#work-sample-story-body h2 {
    font-size: 2rem;
    font-weight: 600;
}
#work-sample-story-body h3 {
    font-size: 1.8rem;
    font-weight: 500;
}
#work-sample-story-body h4 {
    font-size: 1.6rem;
    font-weight: 500;
}
#work-sample-story-body h5 {
    font-size: 1.4rem;
    font-weight: 500;
}
#work-sample-story-body h6 {
    font-size: 1.3rem;
    font-weight: 500;
}

#work-sample-story-body p {
    margin: 2rem 0;
}

#work-sample-story-body strong {
    font-weight: 700;
}

#work-sample-story-body img {
    width: 100%;
    display: block;
    margin: 2rem auto;
    border-radius: 5px;
}
#work-sample-story-body figcaption {
    display: none;
}

#work-sample-story-body ul {
    margin: 1.5rem 0 2.2rem 0;
}

#work-sample-story-body li {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 140%;

    margin: 0 0 0.8rem 1.5rem;

    list-style: disc;
}
#work-sample-story-body li a {
    color: rgb(42, 118, 219);
    font-weight: 600;
}

#work-sample-story-body code {
    font-size: 1.15rem;
    background-color: rgb(230, 230, 230);
    margin: 0;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    #work-sample-story {
        width: 90%;
    }
    #work-sample-story-body {
        width: 60%;
        margin: 0 auto;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #work-sample-story {
        width: 95%;
        margin: 3.5rem auto;
    }

    #work-sample-story-body h1 {
        margin: 3rem auto;
    }
    #work-sample-story-body p {
        font-size: 1.4rem;
    }

}