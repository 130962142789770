@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500&display=swap');

* {
    font-family: 'Urbanist', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(13, 36, 56);
    color: rgb(227, 242, 255);
    margin: 0 auto;
}

h1 {
    font-size: 2.2rem;
}

p {
    font-size: 1.25rem;
    line-height: 140%;
    margin-bottom: 1rem;
}
p>a {
    color: rgb(42, 118, 219);
}
p>a:hover {
    opacity: 0.8;
}

a {
    color: rgb(42, 118, 219);
    text-decoration: none;
}
a:hover {
    cursor: pointer;
}

ul {
    list-style: none;
}

img {
    max-width: 100%;
}

.section {
    margin-bottom: 3.5rem;
}

.desktopOnly {
    display: block;
}
.mobileOnly {
    display: none;
}

.invisible {
    display: none;
}
.visible {
    display: block;
}



/* ---------------------- */
/* ---------------------- */
/* ---- TABLET ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) and (max-width: 1299px) {

    body {
        margin: 0 auto 4rem auto;
    }

}



/* ---------------------- */
/* ---------------------- */
/* -- MOBILE + TABLETS -- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 999px) {

    .mobileOnly {
        display: block;
    }
    .desktopOnly {
        display: none;
    }

    body {
        margin: 3rem auto;
    }

    p {
        font-size: 1.2rem;
    }

}